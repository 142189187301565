import { ROLES } from 'src/app/app.const';
import { NavbarComponent } from './navbar.component';

export interface QMenuItem {
    label: string,
    route?: string[],
    subItems?: QMenuItem[],
    guard?: string[],
    click?: Function,
    href?: string,
    icon?: string,
    classes?: string,
    onlyForMobile?: boolean,
    onlyForDesktop?: boolean,
    isFixed?: boolean // Will always be present irespective of login or not
    id?: string,
    separator?: boolean,
    visibility?: Function,
    badge?: QMenuItemBadge
}

export interface QMenuItemBadge {
    label?: string,
    icon?: string,
    classes?: string
}

export const NAVBAR_MENU_CONFIG = (self: NavbarComponent): QMenuItem[] => {
    return [
        {
            label: 'Home',
            route: ['/home'],
            onlyForMobile: true,
            classes: 'home_menu_option',
            guard: [ROLES.VISITOR]
        },
        {
            label: 'Dashboard',
            guard: [ROLES.ADMIN, ROLES.STYLIST],
            subItems: [
                {
                    label: `Summary`,
                    route: ['/console/summary'],
                    guard: [ROLES.ADMIN],
                },
                {
                    label: 'Manage Subscribers',
                    route: ['/console/support/subscribers'],
                    guard: [ROLES.ADMIN],
                    id: 'manage_subscribers'
                },
                {
                    label: 'My Subscribers',
                    route: ['/console/overview'],
                    guard: [ROLES.STYLIST]
                }
            ]
        },
        {
            label: 'Queues',
            guard: [ROLES.STYLIST],
            subItems: [
                {
                    label: `All Queues`,
                    route: ['/console/all-queues'],
                    guard: [ROLES.STYLIST],
                },
                {
                    label: `Today's Queues`,
                    route: ['/console/all-queues/todays'],
                    guard: [ROLES.STYLIST],
                }
            ]
        },
        {
            label: 'Inventory',
            guard: [ROLES.ADMIN, ROLES.STYLIST],
            subItems: [
                {
                    label: 'Overview',
                    route: ['/console/inventory/overview'],
                    guard: [ROLES.ADMIN, ROLES.STYLIST],
                },
                {
                    label: 'Stock Check',
                    route: ['/console/inventory/stock'],
                    guard: [ROLES.ADMIN, ROLES.STYLIST],
                },
                {
                    label: 'Collections',
                    route: ['/console/inventory/collections'],
                    guard: [ROLES.ADMIN, ROLES.STYLIST],
                }
            ]
        },
        {
            label: 'BigQuery',
            guard: [ROLES.ADMIN, ROLES.STYLIST],
            subItems: [
                {
                    label: 'Apex',
                    route: ['/console/apex'],
                    guard: [ROLES.ADMIN, ROLES.STYLIST],
                },
                {
                    label: 'Tagged Inventory',
                    route: ['/console/tagged-inventory'],
                    guard: [ROLES.ADMIN, ROLES.STYLIST],
                }
            ]
        },
        {
            label: 'Campaigns',
            guard: [ROLES.STYLIST],
            subItems: [
                {
                    label: 'Campaigns',
                    route: ['/console/campaigns'],
                    guard: [ROLES.STYLIST]
                },
                {
                    label: 'Segments',
                    route: ['/console/segments'],
                    guard: [ROLES.STYLIST]
                },
                {
                    label: 'Convos',
                    route: ['/console/convos'],
                    guard: [ROLES.STYLIST]
                }
            ]
        },
        {
            label: 'Metadata',
            guard: [ROLES.ADMIN],
            subItems: [
                {
                    label: 'Retailers',
                    route: ['/console/metadata/retailers'],
                    guard: [ROLES.ADMIN],
                },
                {
                    label: 'Brands',
                    route: ['/console/metadata/brands'],
                    guard: [ROLES.ADMIN],
                }
            ]
        },
        {
            label: 'Support',
            guard: [ROLES.ADMIN, ROLES.STYLIST],
            id: 'support',
            subItems: [
                {
                    label: 'Grievances',
                    route: ['/console/support/grievances'],
                    guard: [ROLES.ADMIN],
                    id: 'Grievances',
                    classes: 'grievances'
                },
                {
                    id: 'requests',
                    label: 'Requests',
                    route: ['/console/support/requests'],
                    guard: [ROLES.STYLIST],
                    classes: 'grievances'
                }
            ]
        },
        {
            label: 'The Vault',
            route: ['/influencer/the-vault'],
            guard: [ROLES.INFLUENCER]
        },
        {
            label: 'Favorites',
            separator: true,
            route: ['/influencer/favorites'],
            guard: [ROLES.INFLUENCER]
        },
        {
            label: `My Wishlist`,
            route: ['/internal/my-qatch'],
            guard: [ROLES.SUBSCRIBER],
            classes: 'my_qatch_menu_option favorites_menu_option'
        },
        {
            label: `Outfits`,
            route: ['/internal/outfits'],
            guard: [ROLES.SUBSCRIBER],
            classes: 'outfits_menu_option favorites_menu_option'
        },
        {
            label: 'Sign In',
            route: ['/signin'],
            guard: [ROLES.VISITOR],
            classes: 'home_sign_in'
        },
        {
            label: 'Partner With Us',
            guard: [ROLES.VISITOR],
            href: `https://partners${self.domainSuffix}.joinqatch.com`,
            classes: 'home_partner_with_us'
        },
        {
            label: 'Top Styles',
            route: ['/discover/collections'],
            guard: [ROLES.VISITOR],
            // onlyForDesktop: true,
            classes:'collections_login_menu_option',
        },
        {
            label: 'Shop New',
            route: ['/discover/collections'],
            guard: [ROLES.SUBSCRIBER],
            // onlyForMobile: true,
            classes: 'shop_new_menu_option'
        },
        // {
        //     label: 'Trending',
        //     route: ['/trending'],
        //     guard: [ROLES.SUBSCRIBER],
        //     classes: 'home_trending_login'
        // },
        // {
        //     label: 'Refer a Friend',
        //     guard: [ROLES.SUBSCRIBER],
        //     click: self.onReferAFriend.bind(self),
        //     classes: 'refer-a-friend refer_friend_header'           
        // },
        // {
        //     label: 'Become a VIP',
        //     guard: [ROLES.SUBSCRIBER],
        //     click: self.becomeAVIP.bind(self),
        //     classes: 'become-vip become_vip_header',
        //     visibility: self.isPaymentInfoHeaderVisible.bind(self),
        // },
        // {
        //     label: 'Become a VIP',
        //     route: ['/vip'],
        //     guard: [ROLES.VISITOR]
        // },
        {
            label: 'How It Works',
            route: ['/how-it-works'],
            guard: [ROLES.VISITOR, ROLES.SUBSCRIBER],
            classes: 'home_how_it_works_header_non_login'
        },
        // {
        //     label: 'Style Me',
        //     route: ['/internal/style-me'],
        //     guard: [ROLES.SUBSCRIBER],
        //     classes: 'position-relative glow-on-btn',
        //     visibility: self.isBasicUser.bind(self)
        // },
        {
            label: 'My Account',
            id: 'profile',
            guard: [ROLES.SUBSCRIBER, ROLES.ADMIN, ROLES.STYLIST, ROLES.INFLUENCER],
            classes: 'account',
            subItems: [
                {
                    label: 'Edit Preferences',
                    route: ['/general/edit-account'],
                    icon: 'bi bi-person',
                    guard: [ROLES.ALL],
                    classes: 'edit_account_menu_option'
                },
                {
                    id: 'update-convo-prefs',
                    label: 'Update Convo Prefs',
                    icon: 'bi bi-bag-heart',
                    guard: [ROLES.SUBSCRIBER],
                    route: ['/general/update-convo-prefs'],
                },
                {
                    id: 'change-role',
                    label: 'Change Role',
                    icon: 'bi bi-arrow-left-right',
                    click: self.manageRolesPopup.bind(self)
                },
                {
                    label: 'Support',
                    route: ['/general/support'],
                    icon: 'nc-support-17',
                    guard: [ROLES.ALL],
                    classes: 'support_menu_option'
                },
                {
                    label: 'Log Out',
                    icon: 'nc-button-power',
                    route: ['/logout'],
                    guard: [ROLES.ALL],
                    click: self.logOut.bind(self),
                    classes: 'log_out_menu_option'
                }
            ]
        },
        {
            label: 'About Us',
            route: ['/our-story'],
            classes: 'home_our_story_header'
        },
        {
            label: 'Blog',
            route: ['/blog'],
            href: 'https://qatchcode.com/blog',
            guard: [ROLES.NONE],
        },
        {
            label: 'JOIN NOW',
            route: ['/signup'],
            classes: 'home_sign_up position-relative glow-on-btn',
            onlyForDesktop: true,
            guard: [ROLES.VISITOR]
        }
    ]
}